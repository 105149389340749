<template>
  <div class="wrap-edit">
    <el-card>
      <FloatTitBtn>
        <div class="edit-header">
          <span>测绘与地理信息准（不）予行政许可决定书</span>
          <div>
            <el-button type="primary" @click="onSubmit">保存</el-button>
            <el-button type="primary" @click="print">打印</el-button>
          </div>
        </div>
      </FloatTitBtn>
      <div class="edit-container">
        <el-form
            ref="baseform"
            :label-position="labelPosition"
            :model="form"
            label-width="120px"
        >
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="文号:">
                <el-input v-model="form.WH"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="受理号:">
                <el-input v-model="form.SLH" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="申请日期:">
                <el-date-picker
                    disabled
                    style="width: 100%"
                    v-model="form.SQRQ"
                    placeholder="请选择接件日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="申请人:">
                <el-input v-model="form.SQR" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="法定代表人（负责人）:">
                <el-input v-model="form.FDDBR"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="地址:">
                <el-input v-model="form.DZ"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="许可内容（不予许可原因）:">
                <el-input
                    v-model="form.XKNR"
                    type="textarea"
                    :rows="3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12"></el-col>
            <el-col :span="12">
              <el-form-item label="日期:">
                <el-date-picker
                    style="width: 100%"
                    :clearable="false"
                    v-model="form.XKRQ"
                    placeholder="请选择接件日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <div v-show="false">
      <div id="forms" style="padding-left: 100px;padding-right: 100px">
        <div
            class="top"
            style="
            font-weight: lighter;
            font-size: 25px;
            margin-bottom: 30px;
          "
        >
          <p class="top-p1" style="line-height: 25px;text-align: center">
            测绘与地理信息
          </p>
          <p class="top-p2" style="line-height: 25px; text-align: center">
            准予行政许可决定书
          </p>
        </div>
        <div class="content-p1" style="text-align: right; margin-bottom: 20px;font-size: 14px;">
          文号&emsp;{{ form.WH }}
        </div>
        <div class="content" style="font-size: 14px; font-weight: lighter;">
          <div class="content-p2" style="margin-bottom: 5px">
            申请人&emsp;&emsp;{{ form.SQR }}
          </div>
          <div class="content-p3" style="margin-bottom: 5px">
            法定代表人（负责人）&emsp;&emsp;{{ form.FDDBR }}
          </div>
          <div class="content-p4" style="margin-bottom: 20px">
            地址&emsp;{{ form.DZ }}
          </div>
        </div>
        <div style="display: flex;justify-content: center;font-size: 14px;margin-top: 20px;">
          <div style="text-indent: 2em">
            你（单位）于<span style="padding: 20px;">
          {{ getDate(form.SQRQ) }}</span>提出的使用基础测绘成果的审批行政许
            <span>可（受理号：<span>{{form.SLH}}</span>)申请, 经本局依法审查,符合法定条件和标准。根</span>
            据《中华人民共和国行政许可法》第三十八条第一款和 《浙江省测绘管理条例》第三十九条的
            规定,决定准予许可。具体许可内容如下:
          </div>
        </div>
        <div style="display: flex;justify-content: center;font-size: 14px">
          <div style=";width: 93%;white-space: pre-wrap;word-wrap: break-word;">
            {{ form.XKNR }}
          </div>
        </div>
        <div style="font-size: 14px; font-weight: lighter">
          <p

              style="text-align: right; margin-top: 50px; margin-bottom: 30px;margin-right: 70px;font-size: 14px"
          >
            (盖章)
          </p>
          <p class="bottom-p2" style="font-size: 14px;display: flex;justify-content: flex-end;margin-right: 10%">{{ datas }}</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {ElMessage} from "element-plus";
import print from "print-js";
import FloatTitBtn from "../../components/FloatTitBtn.vue";
import dayjs from "dayjs";

export default {
  name: 'xkjd',
  components: {FloatTitBtn},
  data() {
    return {
      labelPosition: "left",
      form: {
        Id: "",
        BJID: "",
        WH: "",
        SLH: "",
        SQRQ: new Date(),
        SQR: "",
        FDDBR: "",
        DZ: "",
        XKNR: "",
        XKRQ: new Date(),
      },
      datas:'',
      sblxdisabled: false,
      savebuttonshow: true,
    };
  },
  methods: {
    print() {
      print({
        printable: "forms",
        type: "html",
        scanStyles: false,
        css: "/static/print-style.css",
        documentTitle: "",
      });
    },
    getDate(date) {
      var a = date;
      a= dayjs(date).format('YYYY年MM月DD日');
      if(date == null||date == ''||date == undefined){
        return '';
      }else{
        return a;
      }
    },
    async getlahz() {
      let me = this;
      try {
        await this.$ajax
            .get(
                this.$appConfig.apiUrls.ywxtApi +
                "/api/YW/Getlahz?Id=" +
                me.$route.query.instanceId
            )
            .then((response) => {
              if (response.data.IsSuccess) {
                let res = response.data.Data;
                me.form.SLH = res.AJH;
                me.form.SQRQ = res.JJRQ;
                me.form.SQR = res.JSDW;
                if(me.form.Id ==null||me.form==""){
                  me.form.FDDBR = res.FRDB;
                  me.form.DZ = res.JSWZ;
                }
              } else {
                console.log(response.data.ErrorMessage);
              }
            });
      } catch (error) {
        ElMessage({
          type: "error",
          message: error,
        });
      }
    },
    async onSubmit() {
      let me = this;
      me.form.XKRQ = dayjs(me.form.XKRQ).format('YYYY-MM-DD')
      if (me.checkform()) {
        await this.$ajax
            .post(
                me.$appConfig.apiUrls.ywxtApi + "/api/YW/AddorUpdatexkjd",
                me.form
            )
            .then(function (response) {
              if (response.data.IsSuccess) {
                me.form.Id = response.data.Data;
                me.datas = dayjs(me.form.XKRQ).format('YYYY年MM月DD日')
                // me.form = me.form;
                ElMessage({
                  type: "success",
                  message: "保存成功",
                });
              } else {
                ElMessage({
                  type: "info",
                  message: response.data.errorMessage,
                });
                console.log(response.data.errorMessage);
              }
            });
      }
    },
    //转化json
    switch() {
      let me = this;
      me.form.BJID = this.$route.query.instanceId;
      if (me.form.BJID) {
        this.$ajax
            .get(
                me.$appConfig.apiUrls.ywxtApi +
                "/api/YW/getxkjd?BJID=" +
                me.form.BJID
            )
            .then(function (response) {
              if (response.data.IsSuccess) {
                me.form = response.data.Data;
                if(me.form.XKRQ==null||me.form.XKRQ==''){
                  me.form.XKRQ=new Date()
                }
                me.datas = dayjs(me.form.XKRQ).format('YYYY年MM月DD日')
                me.getlahz();
              } else {
                ElMessage({
                  type: "info",
                  message: "初始化表单失败",
                });
              }
            });
      }
    },
    checkform() {
      let b = true;
      let f = this.form;
      if (b) b = this.checkinput(f.XKNR, "许可内容");
      return b;
    },
    checkinput(val, mess) {
      if (!val || val.length < 1) {
        ElMessage({
          type: "info",
          message: "请输入" + mess,
        });
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.switch();
  },
};
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-form {
  width: 100%;
  overflow: hidden;
}

.input-width {
  width: 100%;
}

.wrap-edit .edit-header {
  margin-bottom: 15px;
}
</style>
